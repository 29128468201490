






































import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { CartItemView, Checkbox, LoadingView, STList, STListItem, STNavigationBar, STToolbar } from "@stamhoofd/components";
import { Cart, CartItem, CartStockHelper, Product, ProductDateRange, Webshop } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        LoadingView,
        Checkbox
    },
    filters: {
        price: Formatter.price.bind(Formatter),
        dateTime: (d: Date) => Formatter.dateTime(d, true)
    }
})
export default class ProductBox extends Mixins(NavigationMixin){
    @Prop({ default: false })
        admin: boolean

    @Prop({ required: true })
        product: Product
    
    @Prop({ required: true })
        webshop: Webshop

    @Prop({ required: true })
        cart: Cart

    @Prop({ required: true })
        saveHandler: (newItem: CartItem, oldItem: CartItem | null) => void

    get price() {
        return this.product.prices[0].price
    }

    get priceString() {
        const priceRanges = Formatter.uniqueArray(this.product.filteredPrices({admin: this.admin}).map(p => p.price))
        if (priceRanges.length == 1) {
            if (priceRanges[0] === 0) {
                if (this.webshop.isAllFree) {
                    return "";
                }
                return "Gratis"
            }
            return Formatter.price(priceRanges[0])
        }
        const minimum = Math.min(...priceRanges)
        const maximum = Math.max(...priceRanges)
        return Formatter.price(minimum, true) + " - " + Formatter.price(maximum, true)
    }

    get count() {
        return this.cart.items.reduce((prev, item) => {
            if (item.product.id != this.product.id) {
                return prev
            }
            return prev + item.amount
        }, 0)
    }

    get pendingReservationCount() {
        return this.cart.items.reduce((prev, item) => {
            if (item.product.id != this.product.id) {
                return prev
            }
            return prev + item.amount - item.reservedAmount
        }, 0)
    }

    get imageResolution() {
        return this.product.images[0]?.getResolutionForSize(100, 100)
    }
    
    get imageSrc() {
        return this.imageResolution?.file.getPublicPath()
    }

    get imgWidth() {
        return this.imageResolution?.width
    }

    get imgHeight() {
        return this.imageResolution?.height
    }

    get stockText() {
        const remainingWithoutCart = CartStockHelper.getRemainingAcrossOptions({ cart: new Cart(), product: this.product, webshop: this.webshop, admin: this.admin}, {inMultipleCartItems: true, excludeOrder: true});

        if (remainingWithoutCart === 0) {
            return {
                text: "Uitverkocht",
                style: "error"
            }
        }

        if (this.editExisting) {
            if (remainingWithoutCart === null || remainingWithoutCart > 25) {
                return null
            }

            const maxOrder = CartStockHelper.getOrderMaximum({ cart: new Cart(), product: this.product, webshop: this.webshop, admin: this.admin});
            if (maxOrder && maxOrder.remaining !== null && maxOrder.remaining < remainingWithoutCart) {
                // No point in showing stock: you can only order x items in one order
                return null;
            }

            return {
                text:  'Nog ' + this.product.getRemainingStockText(remainingWithoutCart),
                style: "warn"
            }
        }


        // How much we can still order from this product
        const maxOrder = CartStockHelper.getOrderMaximum({ cart: this.cart, product: this.product, webshop: this.webshop, admin: this.admin});
        const remaining = CartStockHelper.getRemainingAcrossOptions({ cart: this.cart, product: this.product, webshop: this.webshop, admin: this.admin}, {inMultipleCartItems: true, excludeOrder: true});

        if (maxOrder && maxOrder.remaining === 0) {
            return {
                text: "Maximum bereikt",
                style: "error"
            }
        }
        
        if (remaining === null) {
            return null
        }

        if (remaining > 25 || (maxOrder && maxOrder.remaining !== null && remaining > maxOrder.remaining)) {
            // No point in showing stock: you can only order x items in one order
            return null
        }
    
        if (remaining === 0 ) {
            return {
                text: "Maximum bereikt",
                style: "error"
            }
        }

        return {
            text:  'Nog ' + this.product.getRemainingStockText(remaining),
            style: "warn"
        }
    }

    get editExisting() {
        return this.product.isUnique || !this.webshop.shouldEnableCart
    }

    onClicked() {
        const editExisting = this.editExisting
        const oldItem = editExisting ? this.cart.items.find(i => i.product.id == this.product.id) : undefined

        let cartItem = oldItem?.clone() ?? CartItem.createDefault(this.product, this.cart, this.webshop, {admin: this.admin})

        // refresh: to make sure we display the latest data
        if (oldItem) {
            try {
                cartItem.refresh(this.webshop)
            } catch (e) {
                console.error(e)

                // Not recoverable
                cartItem = CartItem.createDefault(this.product, this.cart, this.webshop, {admin: this.admin})
            }
        }

        if (this.canDismiss) {
            this.show(new ComponentWithProperties(CartItemView, { 
                admin: this.admin,
                cartItem,
                oldItem,
                cart: this.cart,
                webshop: this.webshop,
                saveHandler: this.saveHandler,
            }))
        } else {
            this.present({
                components: [
                    new ComponentWithProperties(NavigationController, {
                        root: new ComponentWithProperties(CartItemView, { 
                            admin: this.admin,
                            cartItem,
                            oldItem,
                            cart: this.cart,
                            webshop: this.webshop,
                            saveHandler: this.saveHandler,
                        })
                    })
                ],
                modalDisplayStyle: "sheet"
            });
        }
    }

    formatDateRange(dateRange: ProductDateRange) {
        return Formatter.capitalizeFirstLetter(dateRange.toString())
    }

}
