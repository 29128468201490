





























































/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { SimpleError } from "@simonbackx/simple-errors";
import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { CategoryBox, CenteredMessage, Checkbox, GlobalEventBus, LegalFooter,LoadingView, OrganizationLogo, PaymentPendingView, ProductGrid, STList, STListItem, STNavigationBar, STToolbar, Toast } from "@stamhoofd/components";
import { SessionManager, UrlHelper } from "@stamhoofd/networking";
import { CartItem, LoginProviderType, Payment, PaymentStatus, WebshopTicketType } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins } from "vue-property-decorator";

import { CheckoutManager } from '../classes/CheckoutManager';
import { WebshopManager } from '../classes/WebshopManager';
import CartView from './checkout/CartView.vue';
import { CheckoutStep, CheckoutStepsManager } from './checkout/CheckoutStepsManager';
import FullPageProduct from "./FullPageProduct.vue";
import OrderView from './orders/OrderView.vue';
import TicketView from "./orders/TicketView.vue";

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        LoadingView,
        Checkbox,
        CategoryBox,
        ProductGrid,
        OrganizationLogo,
        FullPageProduct,
        LegalFooter
    },
    filters: {
        price: Formatter.price.bind(Formatter),
        time: Formatter.time.bind(Formatter),
        dateTime: Formatter.dateTime.bind(Formatter)
    },
    metaInfo() {
        return {
            title: WebshopManager.webshop.meta.name,
            titleTemplate: '%s | '+WebshopManager.organization.name,
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: WebshopManager.webshop.meta.description.text,
                },
                {
                    hid: 'og:site_name',
                    name: 'og:site_name',
                    content: WebshopManager.organization.name
                },
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: WebshopManager.webshop.meta.title ?? WebshopManager.webshop.meta.name
                },
                ...(this.bannerImageSrc ? [
                    {
                        hid: 'og:image',
                        name: 'og:image',
                        content: this.bannerImageSrc
                    },
                    {
                        hid: 'og:image:width',
                        name: 'og:image:width',
                        content: this.bannerImageWidth
                    },
                    {
                        hid: 'og:image:height',
                        name: 'og:image:height',
                        content: this.bannerImageHeight
                    },
                    {
                        hid: 'og:image:type',
                        name: 'og:image:type',
                        content: this.bannerImageSrc.endsWith(".png") ? 'image/png' : 'image/jpeg'
                    },
                ] : [])
            ]
        }
    }
})
export default class WebshopView extends Mixins(NavigationMixin){
    CheckoutManager = CheckoutManager
    CheckoutStepsManager = CheckoutStepsManager
    WebshopManager = WebshopManager
    visible = true

    get isLoggedIn() {
        return SessionManager.currentSession?.isComplete() ?? false
    }

    get userName() {
        return SessionManager.currentSession?.user?.firstName ?? ""
    }

    logout() {
        SessionManager.currentSession?.logout()
    }

    switchAccount() {
        // Do a silent logout
        SessionManager.currentSession?.removeFromStorage()

        // Redirect to login
        SessionManager.currentSession!.startSSO({
            webshopId: this.webshop.id,
            prompt: 'select_account',
            providerType: LoginProviderType.SSO
        }).catch(console.error)
    }

    get organization() {
        return WebshopManager.organization
    }
    
    get webshop() {
        return WebshopManager.webshop
    }

    get cartEnabled() {
        return this.webshop.shouldEnableCart
    }

    get hasTickets() {
        return this.webshop.meta.ticketType === WebshopTicketType.Tickets
    }

    get webshopLayout() {
        return this.webshop.meta.layout
    }

    get cart() {
        return CheckoutManager.cart
    }

    get cartCount() {
        return CheckoutManager.cart.count
    }

    async openCheckout(animated = true) {
        try {
            // Force a save if nothing changed (to fix timeSlot + updated data)
            const nextStep = await CheckoutStepsManager.getNextStep(undefined, false)
            if (!nextStep) {
                throw new SimpleError({
                    code: "missing_config",
                    message: "Er ging iets mis bij het ophalen van de volgende stap"
                })
            }

            this.present({
                animated,
                adjustHistory: animated,
                components: [
                    new ComponentWithProperties(NavigationController, { 
                        initialComponents: [await nextStep.getComponent()]
                    }),
                ],
                modalDisplayStyle: "popup",
                url: UrlHelper.transformUrl(nextStep.url),
            })
        } catch (e) {
            console.error(e)
            Toast.fromError(e).show()
        }
    }

    openCart(animated = true, components: ComponentWithProperties[] = [], url?: string) {
        if (!this.cartEnabled && components.length == 0) {
            this.openCheckout(animated).catch(console.error)
            return
        }
        this.present({
            animated,
            adjustHistory: animated,
            components: [
                new ComponentWithProperties(NavigationController, { 
                    initialComponents: [
                        ...(this.cartEnabled ? [new ComponentWithProperties(CartView)] : []),
                        ...components
                    ] 
                }),
            ],
            modalDisplayStyle: "popup",
            url: UrlHelper.transformUrl(url ?? '/cart')
        })
    }

    get bannerImage() {
        return this.webshop.meta.coverPhoto?.getResolutionForSize(Math.min(document.documentElement.clientWidth - 30, 900), undefined)
    }
    
    get bannerImageWidth() {
        return this.bannerImage?.width
    }

    get bannerImageHeight() {
        return this.bannerImage?.height
    }

    get bannerImageSrc() {
        return this.bannerImage?.file.getPublicPath()
    }

    get isTrial() {
        return this.organization.meta.packages.isWebshopsTrial
    }

    get closed() {
        // 2 minutes in advance already
        return this.webshop.isClosed(2*60*1000) || !this.organization.meta.packages.useWebshops
    }

    get almostClosed() {
        return this.webshop.isClosed(6*60*60*1000) && !this.closed
    }

    get showOpenAt() {
        return this.closed && this.webshop.opensInTheFuture()
    }

    get products() {
        return this.webshop.products.filter(p => !p.hidden)
    }

    get categories() {
        return this.webshop.categories.filter(c => {
            const products = c.productIds.flatMap(id => {
                const product = this.webshop.products.find(p => p.id === id)
                if (product && !product.hidden) {
                    return [product]
                }
                return []
            })
            return products.length > 0
        })
    }
    
    onAddItem(cartItem: CartItem, oldItem: CartItem | null, component) {
        if (this.cartEnabled) {
            const clonedCart = CheckoutManager.cart.clone()
            if (oldItem) {
                clonedCart.removeItem(oldItem)
            }

            cartItem.validate(this.webshop, clonedCart)
            if (component) {
                component.dismiss({force: true})
            }

            if (oldItem) {
                CheckoutManager.cart.removeItem(oldItem)
            }
            CheckoutManager.cart.addItem(cartItem)
            CheckoutManager.saveCart()

            this.openCart(true)
            // if (this.products.length === 1) {
            //this.openCart(true)
            // } else {
            //new Toast(cartItem.product.name+" is toegevoegd aan je winkelmandje", "success green").setHide(2000).show()
            // }
        } else {
            CheckoutManager.cart.clear();
            cartItem.validate(this.webshop, CheckoutManager.cart)
            if (component) {
                component.dismiss({force: true})
            }

            CheckoutManager.cart.addItem(cartItem)
            CheckoutManager.saveCart()
            this.openCheckout(true).catch(console.error)
        }
    }

    mounted() {
        const currentPath = UrlHelper.shared.getPath({ removeLocale: true })
        const path = UrlHelper.shared.getParts();
        const params = UrlHelper.shared.getSearchParams()
        UrlHelper.shared.clear()

        UrlHelper.setUrl("/")

        if (path.length == 2 && path[0] == 'order') {
            const orderId = path[1];
            this.present({
                animated: false,
                adjustHistory: false,
                components: [
                    new ComponentWithProperties(OrderView, { orderId })
                ]
            })
        } else if (path.length == 2 && path[0] == 'tickets') {
            const secret = path[1];
            this.present({
                animated: false,
                adjustHistory: false,
                components: [
                    new ComponentWithProperties(TicketView, { secret })
                ]
            })
        } else if (path.length == 1 && path[0] == 'payment' && params.get("id")) {
            const paymentId = params.get("id")
            const cancel = params.get("cancel") === "true"
            const me = this
            this.present({
                adjustHistory: false,
                animated: false,
                force: true,
                components: [
                    new ComponentWithProperties(PaymentPendingView, { 
                        server: WebshopManager.server, 
                        paymentId,
                        cancel,
                        finishedHandler: function(this: NavigationMixin, payment: Payment | null) {
                            if (payment && payment.status == PaymentStatus.Succeeded) {
                                if (this.modalNavigationController) {
                                    // We are not in a popup: on mobile
                                    // So replace with a force instead of dimissing
                                    this.present({
                                        components: [
                                            new ComponentWithProperties(OrderView, { paymentId: payment.id, success: true })
                                        ],
                                        replace: 1,
                                        force: true
                                    })
                                } else {
                                    // Desktop: push
                                    this.present({
                                        components: [
                                            new ComponentWithProperties(OrderView, { paymentId: payment.id, success: true })
                                        ]
                                    })
                                    this.dismiss({force: true})
                                }
                            } else {
                                this.dismiss({force: true})
                                
                                // Force reload webshop (stock will have changed: prevent invalidating the cart)
                                // Update stock in background
                                WebshopManager.reload().catch(e => {
                                    console.error(e)
                                })

                                new CenteredMessage("Betaling mislukt", "De betaling werd niet voltooid of de bank heeft de betaling geweigerd. Probeer het opnieuw.").addCloseButton(undefined, async () => {
                                    await me.resumeStep('/checkout/payment');
                                }).show()
                            }
                        } 
                    })
                ],
                modalDisplayStyle: "sheet" // warning: if changing to popup: this.present won't work on mobile devices in the finishedhandler (because this is deactivated -> no parents)!
            })
        } else if (path.length == 2 && path[0] == 'checkout') {
            this.resumeStep('/' + path.join('/'), false).catch(e => {
                console.error(e)
            })
        } else if (path.length == 1 && path[0] == 'cart' && this.cartEnabled) {
            this.openCart(false)
        }
    }

    async resumeStep(destination: string, animated = true) {
        // Quickly recreate all steps
        let step: CheckoutStep | undefined = undefined
        const waitingComponents: Promise<ComponentWithProperties>[] = []

        while (!step || step.url !== destination) {
            try {
                const nextStep = await CheckoutStepsManager.getNextStep(step?.id)
                if (!nextStep) {
                    break;
                }
                waitingComponents.push(nextStep.getComponent())
                step = nextStep
            } catch (e) {
                // Possible invalid checkout -> stop here
                break;
            }
        }

        const components = await Promise.all(waitingComponents)
        this.openCart(animated, components, step?.url)
    }

    deactivated() {
        // For an unknown reason, activated is also called when the view is displayed for the first time
        // so we need to only start setting the url when we were deactivated first
        this.visible = false
    }

    beforeDestroy() {
        GlobalEventBus.removeListener(this)
    }

    activated() {
        this.visible = true
    }
}
