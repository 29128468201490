import { render, staticRenderFns } from "./OrderView.vue?vue&type=template&id=3f9def2d&"
import script from "./OrderView.vue?vue&type=script&lang=ts&"
export * from "./OrderView.vue?vue&type=script&lang=ts&"
import style0 from "./OrderView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports