









import { Image } from '@stamhoofd/structures';
import { Component, Prop, Vue } from 'vue-property-decorator';


@Component({})
export default class ImageComponent extends Vue {
    @Prop({ required: true })
        image: Image

    /**
     * Update the height to match the image resolution.
     * Width will take the available space (can style this with css)
     */
    @Prop({ default: false })
        autoHeight: boolean

    @Prop({ default: null })
        maxHeight: number|null

    elWidth: number|null = null;
    elHeight: number|null = null;

    get resolution() {
        return this.image.getResolutionForSize(this.elHeight ?? undefined, this.elHeight ?? undefined)
    }

    get imgWidth() {
        return this.resolution.width
    }

    get imgHeight() {
        return this.resolution.height
    }

    get src() {
        return this.resolution.file.getPublicPath();
    }

    updateSize() {
        this.elWidth = (this.$el as HTMLElement).offsetWidth
        this.elHeight = this.autoHeight ? null : (this.$el as HTMLElement).offsetHeight
    }

    get sizerChildStyle() {
        if (!this.autoHeight) {
            return;
        }
        const percentage = (this.imgHeight / this.imgWidth * 100).toFixed(2);
        return `padding-bottom: ${percentage}%;`
    }

    get sizerStyle() {
        if (!this.autoHeight) {
            return;
        }
        return `max-height: ${this.imgHeight}px;`
    }

    // Observe the size of the element and update the used resolution accordingly
    mounted() {
        // Create a size observer
        try {
            const resizeObserver = new ResizeObserver(() => {
                this.updateSize();
            });
            resizeObserver.observe(this.$el);
        } catch (e) {
            // Not supported
            this.$nextTick(() => {
                this.updateSize();
            })
        }
    }
}
