


















































import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { LegalFooter,OrganizationLogo, STList, STListItem, STNavigationBar, STToolbar } from "@stamhoofd/components";
import { Organization, WebshopPreview } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        OrganizationLogo,
        LegalFooter
    },
    filters: {
        price: Formatter.price.bind(Formatter),
        time: Formatter.time.bind(Formatter)
    },
    metaInfo() {
        return {
            title: this.organization.name + " - Webshops",
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Kies een webshop om door te gaan",
                },
                {
                    hid: 'og:site_name',
                    name: 'og:site_name',
                    content: this.organization.name
                }
            ]
        }
    }
})
export default class ChooseWebshopView extends Mixins(NavigationMixin){
    @Prop({ required: true })
        organization!: Organization;

    @Prop({ required: true })
        webshops!: WebshopPreview[];
      

}
