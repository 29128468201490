























































































































































































import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { BackButton, Checkbox, CopyableDirective, LoadingView, STList, STListItem, STNavigationBar, STToolbar, TooltipDirective } from "@stamhoofd/components";
import { Country, Organization, Payment, TransferDescriptionType, TransferSettings } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CenteredMessage } from '../overlays/CenteredMessage';

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        LoadingView,
        Checkbox,
        BackButton
    },
    filters: {
        price: Formatter.price
    },
    directives: {
        tooltip: TooltipDirective,
        copyable: CopyableDirective
    }
})
export default class TransferPaymentView extends Mixins(NavigationMixin){

    @Prop({ required: true })
        payment: Payment

    @Prop({ default: false })
        created: boolean

    @Prop({ required: true })
        type: "registration" | "order"

    @Prop({ required: true }) 
        organization: Organization

    @Prop({ default: null }) 
        settings: TransferSettings | null

    @Prop({ default: false })
        isPopup: boolean

    @Prop({ default: null })
        finishedHandler: ((payment: Payment | null, component: NavigationMixin) => void) | null

    QRCodeUrl: string | null = null

    isStepsPoppable = false

    mounted() {
        this.generateQRCode().catch(e => console.error(e))
        this.setLeave()
    }

    getOS(): string {
        var userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            return "android";
        }

        if (/Mac OS X 10_14|Mac OS X 10_13|Mac OS X 10_12|Mac OS X 10_11|Mac OS X 10_10|Mac OS X 10_9/.test(userAgent)) {
            // Different sms protocol
            return "macOS-old";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            return "iOS";
        }

        // iPad on iOS 13 detection
        if (navigator.userAgent.includes("Mac") && "ontouchend" in document) {
            return "iOS";
        }

        if (navigator.platform.toUpperCase().indexOf('MAC')>=0 ) {
            return "macOS";
        }

        if (navigator.platform.toUpperCase().indexOf('WIN')>=0 ) {
            return "windows";
        }

        if (navigator.platform.toUpperCase().indexOf('IPHONE')>=0 ) {
            return "iOS";
        }

        if (navigator.platform.toUpperCase().indexOf('ANDROID')>=0 ) {
            return "android";
        }

        return "unknown"
    }

    beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventLeave);
    }

    setLeave() {
        if (!this.created) {
            return;
        }
        window.addEventListener("beforeunload", this.preventLeave);
    }

    preventLeave(event) {
        // Cancel the event
        event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        
        if (this.type === "registration") {
            // Chrome requires returnValue to be set
            event.returnValue = 'Jouw inschrijving is al bevestigd! Je kan niet meer van betaalmethode veranderen.';

            // This message is not visible on most browsers
            return "Jouw inschrijving is al bevestigd! Je kan niet meer van betaalmethode veranderen."
        }
        // Chrome requires returnValue to be set
        event.returnValue = 'Jouw bestelling is al geplaatst! Als je je bestelling gaat aanpassen zal je een tweede bestelling plaatsen!';

        // This message is not visible on most browsers
        return "Jouw bestelling is al geplaatst! Als je je bestelling gaat aanpassen zal je een tweede bestelling plaatsen!"
    }

    shouldNavigateAway() {
        if (!this.created) {
            return true
        }
        return false;
    }

    get isBelgium() {
        return this.organization.address.country == Country.Belgium
    }

    get isStructured() {
        return this.settings?.type == TransferDescriptionType.Structured
    }

    get iban() {
        return this.settings?.iban ?? this.organization.meta.transferSettings.iban ?? "";
    }

    get creditor() {
        return this.settings?.creditor ?? this.organization.name
    }

    get transferDescription() {
        return this.payment.transferDescription
    }

    get formattedTransferDescription() {
        if (this.isStructured && !this.isBelgium && this.transferDescription) {
            return this.transferDescription.match(/.{1,4}/g)?.join(" ") ?? this.transferDescription
        }
        return this.transferDescription
    }

    get qrMessage() {
        const iban = this.iban
        const creditor = this.creditor

        // Note: structured reference still as normal description (the structured reference ISO is not supported)
        return "BCD\n001\n1\nSCT\n\n"+creditor+"\n"+iban+"\nEUR"+(this.payment.price/100).toFixed(2)+"\n\n\n"+this.transferDescription?.substring(0, 140)+"\nhttps://"+this.$t("shared.domains.marketing")+"/docs/betalen-qr-code";
    }

    async generateQRCode() {
        try {
            const QRCode = (await import(/* webpackChunkName: "QRCode" */ 'qrcode')).default
            this.QRCodeUrl = await QRCode.toDataURL(this.qrMessage)
        } catch (e) {
            console.error(e)
            return;
        }
    }

    helpMe() {
        if (this.type === "order") {
            new CenteredMessage("Het lukt niet", "Jouw bestelling is al geplaatst, probeer dus zeker niet opnieuw! Als het scannen niet lukt, kan je gewoon de overschrijving manueel uitvoeren via de vermelde gegevens. Het scannen van de QR-code is niet noodzakelijk, en werkt niet in elke bankapp. Dit is niet te verwarren met een online betaling, de QR-code neemt enkel de gegevens over in je app zodat je sneller zonder typefouten kan overschrijven.").addCloseButton().show()
        } else {
            new CenteredMessage("Het lukt niet", "Jouw inschrijving is al in orde, probeer dus zeker niet opnieuw! Als het scannen niet lukt, kan je gewoon de overschrijving manueel uitvoeren via de vermelde gegevens. Het scannen van de QR-code is niet noodzakelijk, en werkt niet in elke bankapp. Dit is niet te verwarren met een online betaling, de QR-code neemt enkel de gegevens over in je app zodat je sneller zonder typefouten kan overschrijven.").addCloseButton().show()
        }
    }

    goNext() {
        if (this.finishedHandler) {
            this.finishedHandler(this.payment, this)
            return;
        }

        if (this.canDismiss) {
            this.dismiss({ force: true })
            return;
        }
        this.pop({force: true})
    }

}
