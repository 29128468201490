





























































































































































































































































































































































import { ArrayDecoder, Decoder } from '@simonbackx/simple-encoding';
import { ComponentWithProperties, NavigationController, NavigationMixin } from "@simonbackx/vue-app-navigation";
import { BackButton, CenteredMessage, DetailedTicketView,ErrorBox, LoadingButton, LoadingView, Logo,OrganizationLogo, Radio, RecordCategoryAnswersBox, Spinner, STErrorsDefault, STList, STListItem, STNavigationBar, STToolbar, Toast, TransferPaymentView } from "@stamhoofd/components";
import { UrlHelper } from '@stamhoofd/networking';
import { Payment, RecordCategory } from '@stamhoofd/structures';
import { CartItem, Order, OrderStatus, OrderStatusHelper, PaymentMethod, PaymentMethodHelper, PaymentStatus, ProductType, TicketOrder, TicketPublic, WebshopTicketType } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from "vue-property-decorator";

import { CheckoutManager } from '../../classes/CheckoutManager';
import { WebshopManager } from '../../classes/WebshopManager';
import TicketListItem from '../products/TicketListItem.vue';

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        Radio,
        LoadingButton,
        STErrorsDefault,
        LoadingView,
        BackButton,
        OrganizationLogo,
        Spinner,
        TicketListItem,
        RecordCategoryAnswersBox,
        Logo
    },
    filters: {
        price: Formatter.price.bind(Formatter),
        priceChange: Formatter.priceChange.bind(Formatter),
        date: Formatter.dateWithDay.bind(Formatter),
        dateTime: Formatter.dateTimeWithDay.bind(Formatter),
        minutes: Formatter.minutes.bind(Formatter),
        capitalizeFirstLetter: Formatter.capitalizeFirstLetter.bind(Formatter)
    }
})
export default class OrderView extends Mixins(NavigationMixin){
    loading = false
    errorBox: ErrorBox | null = null
    CheckoutManager = CheckoutManager

    @Prop({ default: null })
        orderId: string | null

    @Prop({ default: null })
        paymentId: string | null

    @Prop({ default: null })
        initialOrder!: Order | null

    @Prop({ default: false })
        success: boolean

    order: Order | null = this.initialOrder

    tickets: TicketPublic[] = []
    loadingTickets = false

    get organization() {
        return WebshopManager.organization
    }

    get webshop() {
        return WebshopManager.webshop
    }

    get singleTicket() {
        return this.tickets.length == 1 || this.webshop.meta.ticketType === WebshopTicketType.SingleTicket
    }

    get canShare() {
        return !!navigator.share
    }

    get isPaid() {
        return this.order && (this.order.payment === null || this.order.payment.status === PaymentStatus.Succeeded)
    }

    get isTransfer() {
        return this.getDefaultTransferPayment() !== null
    }

    isPaymentTransfer(payment: Payment) {
        return payment.method === PaymentMethod.Transfer
    }

    get closed() {
        // 2 minutes in advance already
        return this.webshop.isClosed(2*60*1000) || !this.organization.meta.packages.useWebshops
    }

    get isFailed() {
        return !this.order || this.order.number === null
    }

    get isCanceled() {
        return !this.order || this.order.status === OrderStatus.Canceled || this.order.status === OrderStatus.Deleted
    }

    get isDeleted() {
        return !this.order || this.order.status === OrderStatus.Deleted
    }

    get hasTickets() {
        return (this.order && this.order.status != OrderStatus.Canceled && this.order.status != OrderStatus.Deleted) && (this.webshop.meta.ticketType === WebshopTicketType.SingleTicket || !!this.order?.data.cart.items.find(i => i.product.type === ProductType.Voucher || i.product.type === ProductType.Ticket))
    }

    get hasSingleTicket() {
        return this.webshop.meta.ticketType === WebshopTicketType.SingleTicket
    }

    get statusName() {
        if (this.isFailed) {
            return 'Mislukt'
        }
        return this.order ? OrderStatusHelper.getName(this.order.status) : ""
    }

    get statusColor() {
        return this.order ? OrderStatusHelper.getColor(this.order.status) : ""
    }

    get publicTickets() {
        return this.tickets
    }

    get recordCategories(): RecordCategory[] {
        if (!this.order) {
            return []
        }
        return RecordCategory.flattenCategoriesForAnswers(
            this.webshop.meta.recordCategories,
            this.order.data.recordAnswers
        )
    }

    get recordAnswers() {
        return this.order?.data.recordAnswers ?? []
    }

    formatFreePrice(price: number) {
        if (price === 0) {
            return ''
        }
        return Formatter.price(price)
    }

    share() {
        navigator.share({
            title: "Bestelling "+WebshopManager.webshop.meta.name,
            text: "Bekijk mijn bestelling bij "+WebshopManager.webshop.meta.name+" via deze link.",
            url: WebshopManager.webshop.getUrl(this.organization)+"/order/"+this.order!.id,
        }).catch(e => console.error(e))
    }

    getName(paymentMethod: PaymentMethod): string {
        return PaymentMethodHelper.getNameCapitalized(paymentMethod, this.order?.data.paymentContext)
    }

    getLowerCaseName(paymentMethod: PaymentMethod): string {
        return PaymentMethodHelper.getName(paymentMethod, this.order?.data.paymentContext)
    }

    openTransferView(payment: Payment) {
        if (payment.method == PaymentMethod.Transfer) {
            this.present(new ComponentWithProperties(NavigationController, {
                root: new ComponentWithProperties(TransferPaymentView, {
                    type: "order",
                    payment,
                    organization: WebshopManager.organization,
                    settings: WebshopManager.webshop.meta.transferSettings,
                    isPopup: true
                })
            }).setDisplayStyle("popup"))
        }
    }

    getDefaultTransferPayment() {
        const payments = this.order?.payments.filter(p => p.method === PaymentMethod.Transfer && p.price >= 0) ?? []
        return payments[0] ?? null
    }

    async checkTickets() {
        if (!this.hasTickets || !this.order || (!this.isPaid && this.isTransfer)) {
            return
        }
        this.loadingTickets = true

        try {
            const response = await WebshopManager.server.request({
                method: "GET",
                path: "/webshop/" +WebshopManager.webshop.id + "/tickets",
                query: {
                    // Required because we don't need to repeat item information (network + database impact)
                    orderId: this.order.id
                },
                decoder: new ArrayDecoder(TicketOrder as Decoder<TicketOrder>)
            })
            this.tickets = response.data.map(ticket => ticket.getPublic(this.order!)).sort(TicketPublic.sort)
        } catch (e) {
            Toast.fromError(e).show()
        }        

        this.loadingTickets = false
    }

    mounted() {
        if (this.success) {
            CheckoutManager.cart.items = []
            CheckoutManager.saveCheckout()

            // Update stock in background
            WebshopManager.reload().catch(e => {
                console.error(e)
            })
        }
        if (this.order) {
            UrlHelper.setUrl("/order/"+this.order.id)
            this.checkTickets().catch(console.error)
            return;
        }
        // Load order
        if (this.orderId) {
            UrlHelper.setUrl("/order/"+this.orderId)

            WebshopManager.server
                .request({
                    method: "GET",
                    path: "/webshop/" +WebshopManager.webshop.id + "/order/"+this.orderId,
                    decoder: Order as Decoder<Order>,
                }).then(response => {
                    const order = response.data
                    this.order = order
                    this.checkTickets().catch(console.error)
                }).catch(e => {
                    // too: handle this
                    console.error(e)
                    new CenteredMessage("Ongeldige bestelling", "De bestelling die je opvraagt bestaat niet (meer)", "error").addCloseButton().show()
                    this.pop({ force: true })
                })
        } else {
            if (!this.paymentId) {
                throw new Error("Missing payment id or order id")
            }
            WebshopManager.server
                .request({
                    method: "GET",
                    path: "/webshop/" +WebshopManager.webshop.id + "/payment/"+this.paymentId+"/order",
                    decoder: Order as Decoder<Order>,
                }).then(response => {
                    const order = response.data
                    this.order = order
                    UrlHelper.setUrl("/order/"+this.order.id)
                    this.checkTickets().catch(console.error)
                }).catch(e => {
                    // too: handle this
                    console.error(e)
                    new CenteredMessage("Ongeldige bestelling", "De bestelling die je opvraagt bestaat niet (meer)", "error").addCloseButton().show()
                    this.pop({ force: true })
                })
        }
    }

    async downloadAllTickets() {
        const TicketBuilder = (await import(
            /* webpackChunkName: "TicketBuilder" */
            /* webpackPrefetch: true */
            '@stamhoofd/ticket-builder'
        )).TicketBuilder

        const builder = new TicketBuilder(this.publicTickets, this.webshop, WebshopManager.organization, this.order ?? undefined)
        await builder.download()
    }

    openTicket(ticket: TicketPublic) {
        this.present({
            components: [
                new ComponentWithProperties(NavigationController, {
                    root: new ComponentWithProperties(DetailedTicketView, {
                        ticket: ticket,
                        order: this.order,
                        webshop: this.webshop,
                        organization: this.organization
                    })
                })
            ],
            modalDisplayStyle: "sheet"
        })
    }

    imageSrc(cartItem: CartItem) {
        return cartItem.product.images[0]?.getPathForSize(100, 100)
    }
}
