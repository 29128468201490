
































































import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { CartReservedSeat, ReservedSeat, SeatingPlan, SeatingPlanRow, SeatingPlanSeat, SeatingPlanSection, SeatingSizeConfiguration, SeatMarkings } from "@stamhoofd/structures";
import { Formatter } from "@stamhoofd/utility";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";

import { Toast } from "../overlays/Toast";


@Component({
    components: {
        
    },
})
export default class SeatSelectionBox extends Mixins(NavigationMixin) {
    @Prop({ default: false })
        admin: boolean
        
    @Prop({ required: false, default: null})
        sizeConfig: SeatingSizeConfiguration|null

    @Prop({ required: true })
        seatingPlan!: SeatingPlan

    @Prop({ required: true })
        seatingPlanSection!: SeatingPlanSection

    @Prop({ required: false, default: null })
        amount: number|null

    @Prop({ required: false, default: () => []  })
        seats!: ReservedSeat[]

    /**
     * Seats that are not available for selection anymore
     */
    @Prop({ required: false, default: () => [] })
        reservedSeats!: ReservedSeat[]

    @Prop({ required: false, default: () => [] })
        highlightSeats!: ReservedSeat[]

    @Prop({ required: false })
        setSeats?: (seats: ReservedSeat[]) => void

    @Prop({ required: false })
        onClickSeat?: (seat: ReservedSeat) => void

    @Prop({ required: false })
        onHoverSeat?: (seat: ReservedSeat) => void

    lastPriceToast: Toast|null = null

    created() {
        this.seatingPlanSection.updatePositions(this.sizeConfig ?? this.defaultSizeConfig)

        // Default selection
        if (this.amount && this.setSeats) {
            // if (this.seats.length < this.amount) {
            //     let seats: ReservedSeat[] = []
            //     const plan = this.seatingPlan
            //     for (const section of plan.sections) {
            //         for (const row of section.rows) {
            //             for (const seat of row.seats) {
            //                 if (seats.length >= this.amount) {
            //                     break
            //                 }
            // 
            //                 if (seat.isSpace) {
            //                     continue
            //                 }
            // 
            //                 const s = ReservedSeat.create({
            //                     section: section.id,
            //                     row: row.label,
            //                     seat: seat.label
            //                 })
            // 
            //                 if (this.reservedSeats.find(r => r.equals(s))) {
            //                     continue
            //                 }
            // 
            //                 seats.push(
            //                     s
            //                 )
            //             }
            //         }
            //     }
            //     this.setSeats?.(seats)
            // }
            let updatedSeats = this.seats.slice()

            // Remove invalid seats
            updatedSeats = updatedSeats.filter(seat => {
                return this.seatingPlan.isValidSeat(seat, this.reservedSeats)
            })

            if (updatedSeats.length > this.amount) {
                this.setSeats(updatedSeats.slice(0, this.amount))
            } else {
                this.setSeats(updatedSeats)
            }
        }
    }

    mounted() {
        setTimeout(() => {
            if (this.$refs.selectedSeats) {
                const selectedSeats = this.$refs.selectedSeats as HTMLElement[]
                if (selectedSeats.length > 0) {
                    const bounds = selectedSeats[0].getBoundingClientRect()
                    const scrollHeight = selectedSeats[0].closest('main')?.clientHeight

                    if (!scrollHeight) {
                        return
                    }

                    if (bounds.top + bounds.height > scrollHeight - 30) {
                        selectedSeats[0].scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                            inline: "center"
                        })

                        // iOS fix:
                        document.documentElement.scrollTop = 0;
                    }
                }
            }
        }, 400);
    }

    @Watch('seatingPlanSection')
    onPlanChange() {
        this.seatingPlanSection.updatePositions(this.sizeConfig ?? this.defaultSizeConfig)
    }

    get rows() {
        return this.seatingPlanSection.rows
    }

    get defaultSizeConfig() {
        if (!this.setSeats && (this as any).$isMobile) {
            return new SeatingSizeConfiguration({
                seatWidth: 20,
                seatHeight: 20,
                seatXSpacing: 2,
                seatYSpacing: 8
            })
        }

        if ((this as any).$isMobile) {
            return new SeatingSizeConfiguration({
                seatWidth: 30,
                seatHeight: 30,
                seatXSpacing: 3,
                seatYSpacing: 8
            })
        }
        return new SeatingSizeConfiguration({
            seatWidth: 28,
            seatHeight: 28,
            seatXSpacing: 3,
            seatYSpacing: 8
        })
    }

    get size() {
        return this.seatingPlanSection.calculateSize(this.sizeConfig ?? this.defaultSizeConfig)
    }

    isDisabledPersonSeat(seat: SeatingPlanSeat) {
        return seat.markings.includes(SeatMarkings.DisabledPerson)
    }  

    isSelected(row: SeatingPlanRow, seat: SeatingPlanSeat) {
        const s = ReservedSeat.create({
            section: this.seatingPlanSection.id,
            row: row.label,
            seat: seat.label
        })
        
        for (const reservedSeat of this.seats) {
            if (reservedSeat.equals(s)) {
                return true
            }
        }
        return false;
    }

    isHighlighted(row: SeatingPlanRow, seat: SeatingPlanSeat) {
        const s = ReservedSeat.create({
            section: this.seatingPlanSection.id,
            row: row.label,
            seat: seat.label
        })
        return !!this.highlightSeats.find(r => r.equals(s))
    }

    isOccupied(row: SeatingPlanRow, seat: SeatingPlanSeat) {
        const category = this.seatingPlan.categories.find(c => c.id === seat.category)
        if (!this.admin && category && category.adminOnly) {
            return true;
        }

        const s = ReservedSeat.create({
            section: this.seatingPlanSection.id,
            row: row.label,
            seat: seat.label
        })
        return !!this.reservedSeats.find(r => r.equals(s))
    }

    getSeatColor(seat: SeatingPlanSeat) {
        return this.seatingPlan.getSeatColor(seat)
    }

    onHover(row: SeatingPlanRow, seat: SeatingPlanSeat) {
        if (this.onHoverSeat) {
            this.onHoverSeat(ReservedSeat.create({
                section: this.seatingPlanSection.id,
                row: row.label,
                seat: seat.label
            }))
            return
        }
    }

    onClick(row: SeatingPlanRow, seat: SeatingPlanSeat) {
        if (this.onClickSeat) {
            this.onClickSeat(ReservedSeat.create({
                section: this.seatingPlanSection.id,
                row: row.label,
                seat: seat.label
            }))
            return
        }
        if (!this.setSeats) {
            return
        }

        if (seat.isSpace) {
            return
        }
        if (this.isOccupied(row, seat)) {
            new Toast('Deze plaats is al bezet', 'error red').show()
            return
        }

        if (this.lastPriceToast) {
            this.lastPriceToast.hide()
            this.lastPriceToast = null
        }

        // select/deselect this seat
        const selected = this.isSelected(row, seat)
        if (selected) {
            // deselect
            this.setSeats(
                this.seats.filter(s => s.section !== this.seatingPlanSection.id || s.row !== row.label || s.seat !== seat.label)
            )
        } else {
            // select
            const addedSeat = ReservedSeat.create({
                section: this.seatingPlanSection.id,
                row: row.label,
                seat: seat.label
            })

            let seats = [...this.seats, addedSeat]

            if (this.amount && seats.length > this.amount) {
                // Remove seat that is the furthest away
                let currentPosition = {x: seat.x, y: seat.y}

                let furthestSeat: {seat: ReservedSeat, distance: number} | null = null

                for (const s of seats) {
                    if (s.equals(addedSeat)) {
                        continue
                    }
                    if (s.section !== this.seatingPlanSection.id) {
                        continue
                    }

                    const row = this.seatingPlanSection.rows.find(r => r.label === s.row)
                    if (!row) {
                        continue
                    }

                    const seat = row.seats.find(ss => ss.label === s.seat)
                    if (!seat) {
                        continue
                    }

                    const distance = Math.sqrt(Math.pow(currentPosition.x - seat.x, 2) + Math.pow(currentPosition.y - seat.y, 2))
                    if (!furthestSeat || distance > furthestSeat.distance) {
                        furthestSeat = { seat: s, distance }
                    }
                }

                if (furthestSeat) {
                    seats = seats.filter(s => !s.equals(furthestSeat!.seat))
                } else {
                    // Remove first
                    seats = seats.slice(1)
                }
            }

            this.setSeats(seats)

            // Show a toast if price is higher
            const cartReservedSeat = CartReservedSeat.create(addedSeat)
            cartReservedSeat.calculatePrice(this.seatingPlan)

            if (cartReservedSeat.price > 0) {
                this.lastPriceToast = new Toast('Deze plaats heeft een meerprijs van ' + Formatter.price(cartReservedSeat.price), 'info')
                this.lastPriceToast.show()
            }
        }
    }
}
