





































import { NavigationMixin } from '@simonbackx/vue-app-navigation';
import { STErrorsDefault, STList, STListItem, STNavigationBar, STToolbar } from '@stamhoofd/components';
import { Order, TicketPublic, Webshop } from '@stamhoofd/structures';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import SeatSelectionBox from './SeatSelectionBox.vue';

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STErrorsDefault,
        SeatSelectionBox,
        STListItem,
        STList
    }
})
export default class ShowSeatsView extends Mixins(NavigationMixin){
    @Prop({ required: true })
        ticket: TicketPublic

    @Prop({ required: false, default: null })
        order: Order | null

    @Prop({ required: true })
        webshop: Webshop

    @Prop({ default: true })
        allowDismiss: boolean

    get seatingPlanSection() {
        const plan = this.seatingPlan
        if (!plan) {
            return null
        }
        const seat = this.ticket.seat
        if (!seat) {
            return plan.sections[0]
        }
        return plan.sections.find(s => s.id === seat.section) ?? null
    }

    get seatingPlan() {
        const id = this.ticket.getSeatingPlanId()
        return this.webshop.meta.seatingPlans.find(p => p.id === id)
    }

    get seats() {
        const seat = this.ticket.seat
        if (!seat) {
            return []
        }
        return [seat]
    }

    get seatDescription() {
        const seat = this.ticket.seat
        const product =  this.ticket.items[0]?.product
        if (!seat || !product) {
            return []
        }
        return seat.getName(this.webshop, product)
    }

    get reservedSeats() {
        const product = this.ticket.items[0]?.product
        if (product) {
            const updatedProduct = this.webshop.products.find(p => p.id === product.id)
            if (updatedProduct) {
                return updatedProduct.reservedSeats
            }
        }
        return []
    }

    get highlightSeats() {
        // Other seats from this order (if any)
        if (!this.order) {
            return []
        }
        const product = this.ticket.items[0]?.product
        if (!product) {
            return []
        }
        return this.order.data.cart.items.filter(i => i.product.seatingPlanId === this.seatingPlan?.id && i.product.id === product.id).flatMap(i => i.seats)
    }
}
