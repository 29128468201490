





























































































import { ComponentWithProperties, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { ImageComponent, OrganizationLogo, ShowSeatsView, STErrorsDefault, STList, STListItem, STNavigationBar, STToolbar } from '@stamhoofd/components';
import { Order, Organization, ProductDateRange, TicketPublic, Webshop, WebshopTicketType } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from 'vue-property-decorator';


@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STErrorsDefault,
        OrganizationLogo,
        STList,
        STListItem,
        ImageComponent
    },
    filters: {
        price: Formatter.price.bind(Formatter),
        priceChange: Formatter.priceChange.bind(Formatter)
    }
})
export default class DetailedTicketView extends Mixins(NavigationMixin){
    @Prop({ default: false })
        logo: boolean

    @Prop({ required: true })
        webshop: Webshop

    @Prop({ required: true })
        organization: Organization

    @Prop({ required: true })
        ticket: TicketPublic

    @Prop({ required: false, default: null })
        order: Order | null

    @Prop({ default: true })
        allowDismiss: boolean

    QRCodeUrl: string | null = null
    visibleSponsor = 0;

    get cartItem() {
        // TODO: multiple item support needed!
        return this.ticket.items[0]
    }

    get indexDescription() {
        return this.ticket.getIndexDescription(this.webshop)
    }

    get changedSeatString() {
        return this.ticket.getChangedSeatString(this.webshop, true)
    }

    get sponsors() {
        return (this.webshop.meta.sponsors?.sponsors ?? []).filter(s => s.onTickets && (s.banner||s.logo))
    }

    get name() {
        return this.ticket.getTitle()
    }

    get canShare() {
        return !!navigator.share
    }

    get price() {
        return this.ticket.getPrice(this.order)
    }

    get isSingle() {
        return this.webshop.meta.ticketType === WebshopTicketType.SingleTicket
    }

    share() {
        navigator.share({
            title: this.webshop.meta.name+" - "+this.name,
            text: "Bekijk en sla jouw ticket op via deze link.",
            url: this.qrMessage,
        }).catch(e => console.error(e))
    }

    showSeats() {
        this.show({
            components: [
                new ComponentWithProperties(ShowSeatsView, {
                    webshop: this.webshop,
                    ticket: this.ticket,
                    order: this.order,
                    allowDismiss: this.allowDismiss
                })
            ]
        })
    }

    formatPrice(price: number) {
        return Formatter.price(price)
    }

    formatDateRange(dateRange: ProductDateRange) {
        return Formatter.capitalizeFirstLetter(dateRange.toString())
    }

    get qrMessage() {
        return "https://"+this.webshop.getUrl(this.organization) + "/tickets/"+this.ticket.secret
    }

    timer: NodeJS.Timer;

    mounted() {
        this.generateQRCode().catch(console.error)
        this.visibleSponsor = Math.floor(Math.random() * Math.max(0, this.sponsors.length - 1))

        this.timer = setInterval(() => {
            console.log('tick')
            if (this.visibleSponsor + 1 >= this.sponsors.length) {
                this.visibleSponsor = 0;
            } else {
                this.visibleSponsor = this.visibleSponsor+1
            }
        }, 3000)
    }

    beforeDestroy() {
        clearInterval(this.timer);
    }

    async download() {
        const TicketBuilder = (await import(
            /* webpackChunkName: "TicketBuilder" */
            /* webpackPrefetch: true */
            '@stamhoofd/ticket-builder'
        )).TicketBuilder

        const builder = new TicketBuilder([this.ticket], this.webshop, this.organization, this.order ?? undefined)
        await builder.download()
    }

    async generateQRCode() {
        const QRCode = (await import(/* webpackChunkName: "QRCode" */ 'qrcode')).default

        // Increase scanning speed on mobile screens by adding more correction levels
        this.QRCodeUrl = await QRCode.toDataURL(this.qrMessage, { 
            margin: 0, 
            width: 370, 
            height: 370,
            dark: "#000000",
            color: "#ffffff",
        })
    }

    shouldNavigateAway() {
        return this.allowDismiss
    }
}
