













import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { ErrorBox, STInputBox } from "@stamhoofd/components"
import { WebshopField, WebshopFieldAnswer } from '@stamhoofd/structures';
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        STInputBox
    }
})
export default class FieldBox extends Mixins(NavigationMixin){
    @Prop({ default: true })
        withTitle: boolean

    @Prop({ required: true })
        field: WebshopField

    @Prop({ required: true })
        errorBox: ErrorBox

    @Prop({ required: true })
        answers: WebshopFieldAnswer[]

    get value() {
        return this.answers.find(a => a.field.id === this.field.id)?.answer ?? ""
    }

    set value(value: string) {
        const answer = this.answers.find(a => a.field.id === this.field.id)

        if (answer) {
            answer.answer = value
        } else {
            this.answers.push(WebshopFieldAnswer.create({
                field: this.field,
                answer: value
            }))
        }
    }
}
