


























import { NavigationMixin } from "@simonbackx/vue-app-navigation";
import { Logo } from "@stamhoofd/components"
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
    components: {
        Logo
    },
    metaInfo() {
        return {
            meta: [
                {
                    hid: `prerender-status-code`,
                    name: 'prerender-status-code',
                    content: "301"
                },
                {
                    hid: `prerender-header-location`,
                    name: 'prerender-header',
                    content: "Location: "+this.location
                },
                {
                    hid: `prerender-header-cache`,
                    name: 'prerender-header',
                    content: "Cache-Control: no-cache"
                }
            ]
        }
    }

})
export default class PrerenderRedirectView extends Mixins(NavigationMixin){
    @Prop({ required: true })
        location: string
}
