



















































































































































import { ComponentWithProperties, NavigationController, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { BackButton, ErrorBox, NumberInput, Radio, StepperInput, STErrorsDefault, STList, STListItem, STNavigationBar, STToolbar } from '@stamhoofd/components';
import { Cart, CartItem, CartStockHelper, ProductDateRange, ProductPrice, ProductType, Webshop } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import ChooseSeatsView from './ChooseSeatsView.vue';
import FieldBox from './FieldBox.vue';
import OptionMenuBox from './OptionMenuBox.vue';

@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        Radio,
        NumberInput,
        OptionMenuBox,
        StepperInput,
        FieldBox,
        STErrorsDefault,
        BackButton
    },
    filters: {
        price: Formatter.price.bind(Formatter),
        priceChange: Formatter.priceChange.bind(Formatter),
        priceFree: (p: number) => {
            if (p === 0) {
                return "Gratis"
            }
            return Formatter.price(p);
        }
    }
})
export default class CartItemView extends Mixins(NavigationMixin){
    @Prop({ default: false })
        admin: boolean
        
    @Prop({ required: true })
        cartItem: CartItem

    @Prop({ required: true })
        webshop: Webshop

    @Prop({ required: true })
        cart: Cart

    @Prop({ required: true })
        saveHandler: (newItem: CartItem, oldItem: CartItem | null, component) => void

    @Prop({ default: null })
        oldItem: CartItem | null

    errorBox: ErrorBox | null = null

    get willNeedSeats() {
        return this.withSeats
    }

    pluralText(num: number, singular: string, plural: string) {
        return Formatter.pluralText(num, singular, plural)
    }

    addToCart() {
        if (this.willNeedSeats) {
            this.chooseSeats();
            return;
        }
        try {
            this.saveHandler(this.cartItem, this.oldItem, this)
        } catch (e) {
            console.error(e);
            this.errorBox = new ErrorBox(e)
            return
        }
        this.errorBox = null // required if dismiss is disabled
        //this.dismiss({ force: true })
    }

    chooseSeats() {
        const component = new ComponentWithProperties(ChooseSeatsView, {
            cartItem: this.cartItem,
            oldItem: this.oldItem,
            webshop: this.webshop,
            admin: this.admin,
            cart: this.cart,
            saveHandler: this.saveHandler
        });

        if (!this.canDismiss) {
            this.present({
                components: [
                    component
                ],
                modalDisplayStyle: "sheet"
            })
        } else {
            // Sheet
            this.show({
                components: [
                    component
                ]
            })
        }
    }

    get cartEnabled() {
        return this.webshop.shouldEnableCart
    }

    get withSeats() {
        return this.cartItem.product.seatingPlanId !== null
    }

    get suffixSingular() {
        if (this.cartItem.product.type == ProductType.Ticket) {
            return "ticket"
        }
        return this.cartItem.product.type == ProductType.Person ? 'persoon' : 'stuk'
    }

    get suffix() {
        if (this.cartItem.product.type == ProductType.Ticket) {
            return "tickets"
        }
        return this.cartItem.product.type == ProductType.Person ? 'personen' : 'stuks'
    }

    get image() {
        return this.cartItem.product.images[0]?.getResolutionForSize(600, undefined)
    }

    get imageSrc() {
        return this.image?.file?.getPublicPath()
    }

    get product() {
        return this.cartItem.product
    }

    get remainingReduced() {
        if (this.cartItem.productPrice.discountPrice === null) {
            return 0
        }
        return this.cartItem.productPrice.discountAmount - this.count
    }

    get discountPrice() {
        return this.cartItem.productPrice.discountPrice ?? 0
    }

    /**
     * Return the total amount of this same product in the cart, that is not this item (if it is editing)
     */
    get count() {
        return this.cart.items.reduce((prev, item) => {
            if (item.product.id != this.product.id) {
                return prev
            }
            return prev + item.amount
        }, 0)  - (this.oldItem?.amount ?? 0)
    }

    get availableStock() {
        return this.cartItem.getAvailableStock(this.oldItem, this.cart, this.webshop, this.admin)
    }

    get maximumRemaining() {
        return this.cartItem.getMaximumRemaining(this.oldItem, this.cart, this.webshop, this.admin)
    }

    get maximumRemainingAcrossOptions() {
        return CartStockHelper.getRemainingAcrossOptions({
            product: this.product,
            oldItem: this.oldItem,
            cart: this.cart,
            webshop: this.webshop,
            admin: this.admin,
            amount: this.cartItem.amount
        }, {inMultipleCartItems: false})
    }

    get stockText() {
        const maximumRemaining = this.maximumRemaining
        return this.availableStock.filter(v => v.text !== null && (!v.remaining || !maximumRemaining || v.remaining <= maximumRemaining)).map(s => s.text)[0]
    }

    getPriceStock(price: ProductPrice) {
        const priceStock = CartStockHelper.getPriceStock({product: this.product, oldItem: this.oldItem, cart: this.cart, productPrice: price, webshop: this.webshop, admin: this.admin, amount: this.cartItem.amount})
        if (!priceStock) {
            return null
        }

        if (priceStock.remaining !== null && this.maximumRemainingAcrossOptions !== null && priceStock.remaining > this.maximumRemainingAcrossOptions) {
            // Doesn't matter to show this
            return null;
        }
        return priceStock
    }

    getPriceStockText(price: ProductPrice) {
        // Don't show text if all options are sold out
        if (this.maximumRemainingAcrossOptions === 0) {
            return null
        }

        return this.getPriceStock(price)?.shortText
    }

    canSelectPrice(price: ProductPrice) {
        if (this.maximumRemainingAcrossOptions === 0) {
            return false
        }

        return this.getPriceStock(price)?.remaining !== 0
    }

    formatDateRange(dateRange: ProductDateRange) {
        return Formatter.capitalizeFirstLetter(dateRange.toString())
    }

    get areSeatsSoldOut() {
        return CartStockHelper.getSeatsStock({product: this.product, oldItem: this.oldItem, cart: this.cart, webshop: this.webshop, admin: this.admin, amount: this.cartItem.amount})?.stock === 0
    }

    get canOrder() {
        return (this.maximumRemaining === null || this.maximumRemaining > 0) && (this.product.isEnabled || this.admin)
        //return (this.admin || ((this.maximumRemaining === null || this.maximumRemaining > 0 || !!this.oldItem) && this.product.isEnabled)) && !this.areSeatsSoldOut
    }

    get canSelectAmount() {
        return this.product.maxPerOrder !== 1 && this.product.allowMultiple
    }

    get totalPrice() {
        return this.cartItem.getPrice(this.cart)
    }

    get administrationFee() {
        return this.webshop.meta.paymentConfiguration.administrationFee.calculate(this.cartItem.getPrice(this.cart))
    }
}
