































import { Component, Prop,Vue, Watch } from "vue-property-decorator";

import StepperInput from "./StepperInput.vue"

@Component({
    components: {
        StepperInput
    }
})
export default class NumberInput extends Vue {
    /** Price in cents */
    @Prop({ default: 0 })
        min!: number | null

    /** Price in cents */
    @Prop({ default: null })
        max!: number | null;

    @Prop({ default: false })
        stepper!: boolean;

    valueString = "";
    valid = true;

    /** Price in cents */
    @Prop({ default: true })
        required!: boolean

    /** Price in cents */
    @Prop({ default: 0 })
        value!: number | null

    @Prop({ default: "" })
        suffix: string;

    @Prop({ default: null })
        suffixSingular: string | null;

    @Prop({ default: "" })
        placeholder!: string

    @Prop({ default: false })
        floatingPoint!: boolean // In cents if floating point, never returns floats!

    @Watch("value")
    onValueChange() {
        this.clean()
    }

    get internalValue() {
        return this.value
    }

    set internalValue(val: number | null) {
        this.$emit("input", val)
    }

    get stepperValue() {
        return this.value ?? this.min ?? 0
    }

    set stepperValue(val: number) {
        this.$emit("input", val)
        this.$nextTick(() => {
            this.clean();
        })
    }

    mounted() {
        this.clean()
    }

    @Watch("valueString")
    onValueChanged(value: string, _oldValue: string) {
        // We need the value string here! Vue does some converting to numbers automatically
        // but for our placeholder system we need exactly the same string
        if (value == "") {
            if (this.required) {
                this.valid = true;
                this.internalValue = Math.max(0, this.min ?? 0);
            } else {
                this.valid = true;
                this.internalValue = null;
            }
        } else {
            if (!value.includes(".")) {
                // We do this for all locales since some browsers report the language locale instead of the formatting locale
                value = value.replace(",", ".");
            }
            const v = parseFloat(value);
            if (isNaN(v)) {
                this.valid = false;
                this.internalValue = this.min ?? 0;
            } else {
                this.valid = true;

                // Remove extra decimals
                this.internalValue = this.constrain(Math.round(v * (this.floatingPoint ? 100 : 1)));
            }
        }
    }

    /// Returns the decimal separator of the system. Might be wrong if the system has a region set different from the language with an unknown combination.
    whatDecimalSeparator(): string {
        const n = 1.1;
        const str = n.toLocaleString().substring(1, 2);
        return str;
    }

    // Restore invalid input, make the input value again
    // And set valueString
    clean() {
        if (!this.valid) {
            return;
        }

        let value = this.value
        if (value === null) {
            if (!this.required) {
                this.valueString = ""
                return
            }
            value = this.min ?? 0
        }

        // Check if has decimals
        const float = value / (this.floatingPoint ? 100 : 1)
        const decimals = float % 1;
        const abs = Math.abs(float);

        if (decimals != 0) {
            // Include decimals
            this.valueString =
                (float < 0 ? "-" : "") +
                Math.floor(abs) +
                this.whatDecimalSeparator() +
                 (""+Math.round(Math.abs(decimals) * (this.floatingPoint ? 100 : 1))).padStart(2, "0");
        } else {
            // Hide decimals
            this.valueString = float + "";
        }
    }

    // Limit value to bounds
    constrain(value: number): number {
        if (this.min !== null && value < this.min) {
            value = this.min;
        } else if (this.max !== null && value > this.max) {
            value = this.max;
        }
        return value
    }

    step(add: number) {
        if (!this.valid) {
            return;
        }
        const v = this.constrain((this.internalValue ?? this.min ?? 0) + add);
        this.internalValue = v
        this.$nextTick(() => {
            this.clean();
        })
        
    }

    focus() {
        (this.$refs["input"] as any).focus()
    }
}
