






























































































import { ComponentWithProperties, NavigationController, NavigationMixin } from '@simonbackx/vue-app-navigation';
import { CartItemView, ErrorBox, LoadingButton, StepperInput, STErrorsDefault, STList, STListItem, STNavigationBar, STToolbar, Toast } from '@stamhoofd/components';
import { UrlHelper } from '@stamhoofd/networking';
import { CartItem, CartStockHelper } from '@stamhoofd/structures';
import { Formatter } from '@stamhoofd/utility';
import { Component, Mixins } from 'vue-property-decorator';

import { CheckoutManager } from '../../classes/CheckoutManager';
import { WebshopManager } from '../../classes/WebshopManager';
import { CheckoutStepsManager } from './CheckoutStepsManager';


@Component({
    components: {
        STNavigationBar,
        STToolbar,
        STList,
        STListItem,
        STErrorsDefault,
        LoadingButton,
        StepperInput
    },
    filters: {
        price: Formatter.price.bind(Formatter),
    }
})
export default class CartView extends Mixins(NavigationMixin){
    CheckoutManager = CheckoutManager

    title = "Winkelmandje"
    loading = false
    errorBox: ErrorBox | null = null

    get cart() {
        return this.CheckoutManager.cart
    }

    get checkout() {
        return this.CheckoutManager.checkout
    }

    get webshop() {
        return WebshopManager.webshop
    }

    async goToCheckout() { 
        if (this.loading) {
            return
        }

        this.loading = true
        this.errorBox = null

        try {
            await CheckoutStepsManager.goNext(undefined, this)
        } catch (e) {
            console.error(e)
            this.errorBox = new ErrorBox(e)
        }
        this.loading = false
    }

    formatFreePrice(price: number) {
        if (price === 0) {
            return ''
        }
        return Formatter.price(price)
    }

    imageSrc(cartItem: CartItem) {
        return this.imageResolution(cartItem)?.file?.getPublicPath()
    }

    imageResolution(cartItem: CartItem) {
        return cartItem.product.images[0]?.getResolutionForSize(100, 100)
    }

    deleteItem(cartItem: CartItem) {
        CheckoutManager.cart.removeItem(cartItem)
        CheckoutManager.saveCart()
    }

    setCartItemAmount(cartItem: CartItem, amount: number) {
        cartItem.amount = amount
        cartItem.calculateUnitPrice(this.cart)
        CheckoutManager.saveCart()
    }

    editCartItem(cartItem: CartItem ) {
        this.present({
            components: [
                new ComponentWithProperties(NavigationController, {
                    root: new ComponentWithProperties(CartItemView, { 
                        cartItem: cartItem.clone(), 
                        oldItem: cartItem,
                        cart: CheckoutManager.cart,
                        webshop: WebshopManager.webshop,
                        saveHandler: (cartItem: CartItem, oldItem: CartItem | null, component) => {
                            cartItem.validate(WebshopManager.webshop, CheckoutManager.cart)
                            component?.dismiss({force: true})
                            if (oldItem) {
                                CheckoutManager.cart.removeItem(oldItem)
                            }
                            CheckoutManager.cart.addItem(cartItem)
                            CheckoutManager.saveCart()
                        }
                    })
                })
            ],
            modalDisplayStyle: "sheet"
        })
    }

    mounted() {
        UrlHelper.setUrl("/cart")
        
        try {
            this.cart.validate(WebshopManager.webshop)
        } catch (e) {
            console.error(e)
            this.errorBox = new ErrorBox(e)
        }
        CheckoutManager.saveCart()
    }

    countFor(cartItem: CartItem) {
        return CheckoutManager.cart.items.reduce((prev, item) => {
            if (item.product.id != cartItem.product.id) {
                return prev
            }
            return prev + item.amount
        }, 0)  - (cartItem.amount ?? 0)
    }

    maximumRemainingStockFor(cartItem: CartItem) {
        if (cartItem.product.remainingStock === null) {
            return null
        }

        return cartItem.product.remainingStock - this.countFor(cartItem)
    }

    maximumRemainingOrderFor(cartItem: CartItem) {
        if (cartItem.product.maxPerOrder === null) {
            return null
        }

        return cartItem.product.maxPerOrder - this.countFor(cartItem)
    }

    maximumRemainingFor(cartItem: CartItem) {
        const admin = false;
        const remaining = cartItem.getMaximumRemaining(cartItem, this.cart, this.webshop, admin);
        return remaining
    }
}
